.card-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    max-width: 1220px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-container-2 {
    border-radius: 10px;
    padding: 20px;
    max-width: 1260px;
    margin: 20px auto;

}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-section {
    flex: 2;
}

.child-name {
    font-size: 3.2em;
    color: #5b6bf5;
    font-weight: bold;
    margin-bottom: 0.3em;
}

.divider {
    width: 1px;
    height: 220px;
    background-color: #ccc;
    margin: 0 20px;
}

.right-section {
    text-align: center;
    flex: 1;
}

.score-label {
    font-size: 25px;
    color: #5b6bf5;
    font-weight: bold;
}

.score-value {
    font-size: 6em;
    color: #5b6bf5;
    font-weight: bold;
    margin: 10px 0;
}

.risk-level {
    color: #666;
    font-size: medium;
}

.left-section p {
    color: #666;
    margin-bottom: 0.5em;
    font-size: 16pt;
}



.div-contet-res {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.details-title {
    font-size: 1.8em;
    font-weight: bold;
    color: #666;
}

.details-subtitle {
    color: #666;
    font-size: 21px;
    margin-bottom: 25px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-right: 20px;
    background-color: #ffffff;
    padding: 1em;
    border-radius: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 1.6em;
    font-weight: bold;
    border-radius: 1em;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s ease;
    /* Adiciona uma transição suave */
}

.grid-item:hover {
    transform: scale(1.1);
    /* Aumenta o tamanho para 110% ao passar o mouse */
}


.low-risk {
    background-color: #a3d9a5;
    /* Cor verde */
}

.high-risk {
    background-color: #f8a5a5;
    /* Cor vermelha */
}

.info-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.info-title {
    font-size: 2.5em;
    color: #5b6bf5;
    font-weight: bold;
    margin-bottom: 10px;
}

.info-card p {
    margin-bottom: 1.2em;
    font-size: 15pt;
    color: #666;
    margin-top: 1em;
}

.risk-description {
    font-weight: bold !important;
    font-size: 14pt !important;
    margin-bottom: 10px !important;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 1em;
    border-radius: 1em;
    width: 626px;
    text-align: start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}

.modal-content h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #666;
}

.modal-content p {
    font-size: 1em;
    margin-bottom: 10px;
    color: #666;
}

.modal-content .resposta {
    color: #3C4CD9;
}

.modal-content button {
    background-color: #4CAF50;
    color: #fff;
    padding: 15px 35px;
    border: none;
    border-radius: 1em;
    cursor: pointer;
    font-size: 12pt;

}

.DowPDF {
    display: flex;
    justify-content: end;
}

.DowPDF button {
    padding: 1em 3em;
    border: none;
    background: #99d49b;
    color: #256327;
    border-radius: 1em;
    cursor: pointer;
    font-size: 12pt;
    font-weight: bold;
    transition: all .5s;
}

.DowPDF button:hover {

    background: #558357;
    color: #fff;


}

.info-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block;
}

@media (max-width: 768px) {
    .child-name {
        font-size: 1.2em;
        color: #5b6bf5;
        font-weight: bold;
        margin-bottom: 0.3em;
    }

    .left-section p {
        color: #666;
        margin-bottom: 0.5em;
        font-size: 10pt;
    }

    .score-value {
        font-size: 4em;
        color: #5b6bf5;
        font-weight: bold;
        margin: 10px 0;
    }

    .score-label {
        font-size: 17px;
        color: #5b6bf5;
        font-weight: bold;
    }

    .risk-level {
        color: #666;
        font-size: 8pt;
    }

    .div-contet-res {
        display: grid;
        grid-template-columns: 1fr;
    }

    .card-container-2 {
        border-radius: 10px;
        padding: 0px;
        max-width: 466px;
        margin: 20px auto;
    }

    .card-container {
        background-color: #ffffff;
        border-radius: 10px;
        padding: 9px;
        max-width: 1220px;
        margin: 20px auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 90%;

    }

    .card-container-2 {
        border-radius: 10px;
        padding: 0px;
        max-width: 90%;
        margin: 20px auto;
        width: 90%;
        margin: 0 auto;
    }

    .grid-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        font-size: 1.6em;
        font-weight: bold;
        border-radius: .5em;
        color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: transform 0.2s ease;
    }

    .info-title {
        font-size: 1.5em;
        color: #5b6bf5;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .info-card p {
        margin-bottom: 1.2em;
        font-size: 11pt;
        color: #666;
        margin-top: 1em;
    }

    .info-card {
        background-color: #fff;
        padding: 10px;
        border-radius: 1em;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: block;
        width: 90%;
        margin-bottom: 1em;
    }

    .div-contet-res {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }



}