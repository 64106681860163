/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal content */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

/* Close button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #4CAF50;
    /* Green background */
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal title and body styling */
.modal-content h2 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 1.5rem;
    color: #666;
}

.modal-body {
    padding: 50px 0;
    font-size: 1rem;
    color: #666;
    text-align: center;
}

.modal-closeBTN {
    display: flex;
    justify-content: end;
}

.modal-closeBTN .modal-content button {
    background-color: #4d4d4d;
    color: #fff;
    padding: 15px 35px;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 12pt;
}

/* Animation for modal */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}