/* Layout container */
.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* Header styles */
.header {
    background-color: #5B6BF5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: white;
    border-radius: 0 0 15px 0;
    height: 80px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.header-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-logo {
    width: 130px;
    margin-right: 10px;
}

.header-title {
    font-size: 2rem;
    font-weight: bold;
}

.header-right {
    display: flex;
    align-items: center;
}

.header-greeting {
    margin-right: 10px;
    font-size: 1.5rem;
}

.user-icon {
    font-size: 4rem;
    color: #ccc;
    margin-right: 3em;
}

/* Sidebar styles */
.sidebar {
    background-color: #5B6BF5;
    padding-top: 20px;
    padding-left: 10px;
    position: fixed;
    left: 0;
    top: 80px;
    width: 300px;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow-y: auto;
}

.sidebar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    gap: 0.5em;
}

.sidebar-nav a {
    display: flex;
    align-items: center;
    padding: 2px;
    margin: 5px 0;
    color: #536dfe;
    font-weight: bold;
    background-color: #ffffff;
    border-radius: 1em;
    text-decoration: none;
    font-size: 1.2rem;
    height: 3.5em;
    box-shadow: 2px 5px 5px #00000021;
    transition: background-color 1s;
}

.sidebar-nav a:hover {
    background-color: #b1b1b1;
}

.sidebar-icon {
    width: 2em;
    margin-right: 20px;
    margin-left: 8px;
}

/* Main content styles */
.main-content {
    margin-top: 90px;
    /* Space for header */
    margin-left: 300px;
    /* Space for sidebar */
    padding: 20px;
    background-color: #E7E7E7;
    height: calc(100vh - 80px);
    overflow-y: auto;
}

/* Mobile styles */
@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: initial;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0px;
    }

    .sidebar-nav {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .sidebar-nav a {
        width: 45%;
        padding: 10px;
        font-size: 1rem;
        margin: 5px;
        height: auto;
    }

    .main-content {
        margin-left: 0;
        margin-top: 20px;
    }

    .header-left {
        display: none;
    }

    .user-icon {
        font-size: 3rem;
        color: #ccc;
        margin-right: 0em;
    }

    .header-content {
        display: block;

        width: 100%;
    }

    .header-right {
        justify-content: space-between;
    }

    .header {

        width: 90%;

    }

    .sidebar-nav {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* Define 3 colunas */
        gap: 10px;
        justify-items: center;
        /* Centraliza os itens */
    }

    .sidebar-nav a {
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        text-align: center;
        /* Centraliza o texto */
        height: auto;
    }

    .main-content {
        margin-left: 0;
        margin-top: 20px;
    }

    .sidebar {
        width: 100%;
        height: auto;
        position: initial;
        flex-direction: row;
        flex-wrap: wrap;

        margin-top: 30%;
        height: 50vh;
        background-color: #E7E7E7;

    }

    .sidebar-nav a {
        display: block;
    }

    .sidebar-icon {
        width: 2em;
        margin-right: 0px;
        margin-left: 0px;
    }



    .sidebar-nav {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        justify-items: center;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0em;

    }

    .sidebar-nav a {
        background-color: #ffffff;
        /* Ajuste a cor de fundo se desejar */
        border-radius: 1em;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        padding: 0;
        text-align: center;
        width: 100%;
        font-size: 1rem;
        color: #333;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: 150px;
        border: 1px solid #BFC3EC;
    }

    .sidebar-icon {
        width: 2.5em;
        height: auto;
        margin-bottom: 10px;
    }

    .sidebar-nav a div {
        font-weight: bold;
    }

    .main-content {
        margin-left: 0;
        margin-top: 20px;
    }

    .contentBGMobi {
        display: flex;
        background: #BFC3EC;
        width: 50px;
        padding: 1em;
        border-radius: 1em 3em 3em 3em;
        justify-content: center;
        align-items: center;

        height: 45px;
    }

    .infoText-mob p {
        margin-top: 15px;
        text-align: left;
        margin-left: 6px;
        width: 95%;
        font-size: 11pt;
        color: #5b6bf5;

    }

    .sidebar {
        background-color: #e7e7e7;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto !important;
        margin-top: 30%;
        position: static;
        width: 100%;
    }

    .header {

        border-radius: 0 0 15px 15px;

    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .sidebar {
        background-color: #5B6BF5;
        padding-top: 20px;
        padding-left: 10px;
        position: fixed;
        left: 0;
        top: 80px;
        width: 280px;
        height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        gap: 1em;
        overflow-y: auto;
    }

    .sidebar-nav a {
        display: flex;
        align-items: center;
        padding: 2px;
        margin: 5px 0;
        color: #536dfe;
        font-weight: bold;
        background-color: #ffffff;
        border-radius: 1em;
        text-decoration: none;
        font-size: 1rem;
        height: 3em;
        box-shadow: 2px 5px 5px #00000021;
    }

    .header-title {
        font-size: 1.4rem;
        font-weight: bold;
    }

    .header-logo {
        width: 110px;
        margin-right: 10px;
    }

    .header-greeting {
        margin-right: 10px;
        font-size: 1.2rem;
    }

    .user-icon {
        font-size: 3rem;
        color: #ccc;
        margin-right: 3em;
    }

}

@media (min-width: 720px) and (max-width: 1280px) {
    .sidebar {
        background-color: #5B6BF5;
        padding-top: 20px;
        padding-left: 10px;
        position: fixed;
        left: 0;
        top: 80px;
        width: 280px;
        height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        gap: 1em;
        overflow-y: auto;
    }

    .sidebar-nav a {
        display: flex;
        align-items: center;
        padding: 2px;
        margin: 5px 0;
        color: #536dfe;
        font-weight: bold;
        background-color: #ffffff;
        border-radius: 1em;
        text-decoration: none;
        font-size: 1rem;
        height: 3em;
        box-shadow: 2px 5px 5px #00000021;
    }

    .header-title {
        font-size: 1.4rem;
        font-weight: bold;
    }

    .header-logo {
        width: 110px;
        margin-right: 10px;
    }

    .header-greeting {
        margin-right: 10px;
        font-size: 1.2rem;
    }

    .user-icon {
        font-size: 3rem;
        color: #ccc;
        margin-right: 3em;
    }

}

