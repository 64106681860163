.recuperar-senha-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffffff;
    color: #3f3d56;
}

.titulo {
    font-size: 45pt;
    color: #5B6BF5;
    font-weight: bold;
    margin-bottom: 10px;
}

.descricao {
    font-size: 20pt;
    color: #5B6BF5;
    margin-bottom: 20px;
    text-align: center;
    width: 80%;
    max-width: 400px;
    margin-top: 1em;
    margin-bottom: 2em;
    font-weight: bold;
}

.label {
    font-size: 15pt;
    color: #9e9e9e;
    margin-bottom: 1em;
}

.input-email-2 {
    width: 80%;
    max-width: 390px;
    padding: 17px;
    font-size: 27px;
    border: 1px solid #ddd;
    border-radius: 1em;
    text-align: center;
    color: #3f3d56;
    margin-bottom: 3em;
}

.input-email::placeholder {
    color: #3f3d56;
}

.botao-envia-2 {
    width: 80%;
    max-width: 400px;
    padding: 19px;
    font-size: 16pt;
    color: #ffffff;
    background-color: #5B6BF5;
    border: none;
    border-radius: 1em;
    cursor: pointer;
    font-weight: bold;
    transition: all .5s;
    margin-left: 0em;
}
.botao-enviar-2 {
    width: 80%;
    max-width: 400px;
    padding: 19px;
    font-size: 16pt;
    color: #5B6BF5;
    background-color: #ffffff;
   
    border: 2px solid #5B6BF5;
    border-radius: 1em;
    cursor: pointer;
    font-weight: bold;
    margin-top: 2em;
    transition: all .5s;
}

.botao-enviar:hover {
    background-color: #2f37c1;
}
.botao-enviar-2:hover {
    background-color: #e6e6e6;
}