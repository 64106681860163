.duvidas-container {
    text-align: center;
    padding: 100px;
    background-color: #e7e7e7;
}

.duvidas-titulo {
    font-size: 30pt;
    color: #5b6bf5;

    font-weight: bold;
}

.duvidas-texto {
    font-size: 20pt;
    width: auto;
    text-align: center;
    color: #6a6a6a;

    line-height: 1.5;
    margin: 0 auto;
    padding-top: 1em;
}

.duvidas-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.email-icon {
    font-size: 180px;
    color: #5b6bf5;
    margin-bottom: 10px;
}

.duvidas-email {
    font-size: 32pt;
    color: #5b6bf5;

    font-weight: bold;
}

.content-bg {
    background: #fff;
    border-radius: 1em;
    box-shadow: 2px 5px 5px #00000021;
    padding: 5em;
    width: 50%;
    margin: 0 auto;
}

@media (max-width: 768px) {


    .duvidas-container {
        text-align: center;
        padding: 13px;
        background-color: #e7e7e7;
    }

    .main-content {

        padding: 0px;

    }

    .content-bg {
        background: #fff;
        border-radius: 1em;
        box-shadow: 2px 5px 5px #00000021;
        padding: 0em;
        width: 100%;
        margin: 0 auto;
    }

    .duvidas-texto {
        font-size: 13pt;
        width: 300px;
        text-align: center;
        color: #6a6a6a;
        line-height: 1.5;
        margin: 0 auto;
        padding-top: 1em;
    }

    .duvidas-titulo {
        font-size: 23pt;
        color: #5b6bf5;
        font-weight: bold;
        margin-top: 0.5em;
        padding-top: 1em;
    }

    .email-icon {
        font-size: 80px;
        color: #5b6bf5;
        margin-bottom: 10px;
    }

    .duvidas-email {
        font-size: 21pt;
        color: #5b6bf5;
        font-weight: bold;
        margin-bottom: 2em;
    }


    .main-content {
        padding: 0;
        height: 100vh;
    }

}

@media (min-width: 900px) and (max-width: 1444px) {
    .duvidas-texto {
        font-size: 15pt;
        width: auto;
        text-align: center;
        color: #6a6a6a;
        line-height: 1.5;
        margin: 0 auto;
        padding-top: 1em;
    }
    .duvidas-titulo {
        font-size: 26pt;
        color: #5b6bf5;
        font-weight: bold;
    }
    .email-icon {
        font-size: 120px;
        color: #5b6bf5;
        margin-bottom: 10px;
    }
    
}

@media (min-width: 768px) and (max-width: 1400px) {
    .duvidas-texto {
        font-size: 14pt;
        width: auto;
        text-align: center;
        color: #6a6a6a;
        line-height: 1.5;
        margin: 0 auto;
        padding-top: 1em;
    }

    .duvidas-titulo {
        font-size: 22pt;
        color: #5b6bf5;
        font-weight: bold;
    }
    .email-icon {
        font-size: 120px;
        color: #5b6bf5;
        margin-bottom: 10px;
    }

    .duvidas-container {
        text-align: center;
        padding: 25px;
        background-color: #e7e7e7;
    }
}





@media (min-width: 720px) and (max-width: 1280px) {
    .duvidas-texto {
        font-size: 14pt;
        width: auto;
        text-align: center;
        color: #6a6a6a;
        line-height: 1.5;
        margin: 0 auto;
        padding-top: 1em;
    }

    .duvidas-titulo {
        font-size: 22pt;
        color: #5b6bf5;
        font-weight: bold;
    }
    .email-icon {
        font-size: 120px;
        color: #5b6bf5;
        margin-bottom: 10px;
    }
}

