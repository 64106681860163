/* PerfilUser.css */


.perfil-container {
    padding: 2em 20em;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.perfil-header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #666;
}

.back-icon {
    cursor: pointer;
    font-size: 24px;
}

.perfil-content {
    display: flex;
    gap: 20px;
}

.perfil-card {
    background-color: #5B6BF5;
    color: white;
    width: 360px;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 1em;
}

.perfil-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3em;

}

.perfil-icon {
    background-color: white;
    color: gray;
    border-radius: 0em;
    width: 130px;
    padding: 15px;
    margin-bottom: 10px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10em;
}

.perfil-info h2 {
    font-size: 1.8em;
    margin: 0;
    text-align: center;
    margin-bottom: 0.3em;
    margin-top: 0.6em;
}

.perfil-info p {
    font-size: 1.3em;
    text-align: center;
    margin: 5px 0;
}

.perfil-info span {
    font-size: 1.3em;
    text-align: center;
    font-weight: bold;
    margin-top: 0.4em;
}

.perfil-settings {
    flex-grow: 1;
    background-color: #fff;
    border-radius: 1em;
    padding: 20px;
    box-shadow: 2px 5px 5px #00000021;
}

.settings-section {
    margin-bottom: 20px;
}

.settings-section h3 {
    font-size: 1.6em;
    color: #666;
    margin-bottom: 10px;

}

.settings-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.settings-item:hover {
    background-color: #e0e0e0;
}

.settings-icon {
    font-size: 1.8em;
    color: #666;
    margin-right: 10px;
}

.arrow-icon {
    font-size: 1.2em;
    color: #888;
}

.settings-item span {
    font-size: 18pt;
    color: #666;
}

.settings-item div {
    display: flex;
    align-items: center;
}

.content-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.back-div-voltar-pf {
    display: none;
}

@media (max-width: 768px) {

    .back-div-voltar-pf {
        display: block;
        background-color: #5B6BF5;
    }

    .perfil-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }



    .perfil-container {
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding: 0em 0em;
    }

    .perfil-header {
        display: none;
    }



    .perfil-card {
        background-color: #5b6bf5;
        border-radius: 0em 0em 12em 12em;
        color: #fff;
        gap: 10px;
        padding: 0em 0em 2em 0em;
        width: auto;
    }


    .perfil-info {
        justify-content: center;
        margin-top: 0em;
    }

    .perfil-info h2 {
        font-size: 1.8em;
        margin: .2em 0 .3em;
        text-align: center;
    }

    .perfil-settings {
        background-color: #fff;
        border-radius: 1em;
        box-shadow: 2px 5px 5px #00000021;
        flex-grow: 1;
        padding: 20px;
        width: 85%;
        margin: 0 auto;
    }

    .settings-item span {
        color: #666;
        font-size: 14pt;
    }

    .settings-icon {
        color: #666;
        font-size: 1.2em;
        margin-right: 10px;
    }

    .arrow-icon {
        color: #888;
        font-size: 1em;
    }

    .settings-section h3 {
        color: #666;
        font-size: 1.2em;
        margin-bottom: 10px;
    }

    .settings-item {
        align-items: center;
        background-color: #fff;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 6px;
        transition: background-color .2s;
    }

    .perfil-content {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .back-div-voltar-pf .back-icon {
        font-size: 1.5em;
        color: #ffffff;
        cursor: pointer;
        margin-top: 1em;
        margin-left: 1em;
    }

    .perfil-settings {
        background-color: #fff;
        border-radius: 1em;
        box-shadow: 2px 5px 5px #00000021;
        flex-grow: 1;
        padding: 20px;
        width: 85%;
        margin: 0 auto;
        margin-top: 1em;
        margin-bottom: 2em;
    }


}


@media (min-width: 900px) and (max-width: 1600px) {
    .perfil-container {
        padding: 2em 9em;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media (min-width: 900px) and (max-width: 1444px) {
    .perfil-container {
        padding: 2em 2em;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}



@media (min-width: 768px) and (max-width: 1400px) {
    .perfil-container {
        padding: 2em 2em;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}


@media (min-width: 720px) and (max-width: 1280px) {
    .perfil-container {
        padding: 2em 2em;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

