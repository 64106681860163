/* NivelTEA.css */

.nivel-tea-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.nivel-tea-card {
    padding: 2em;
    border-radius: 10px;
    width: 100%;
    max-width: 700px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    color: #333;
}

.baixo-risco {
    background-color: #a4f6a5;
}

.moderado-risco {
    background-color: #CACCFF;
}

.alto-risco {
    background-color: #FFC38F;
}

.pontuacao-display-baixo {
    background-color: #54a455;
    color: #a4f6a5;
    font-size: 6em;
}

.pontuacao-display-medio {
    background-color: #3C4CD9;
    color: #CACCFF;
    font-size: 6em;
}

.pontuacao-display-alto {
    background-color: #70441E;
    color: #ffc38f;
    font-size: 6em;
}

.nivel-tea-card h2 {
    font-size: 3em;
    font-weight: bold;
    color: #333;
}

.pontuacao-display {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    margin: 1em 0;
    font-weight: bold;

}

.nivel-risco {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    margin-top: 0.8em;
}

.pontuacao-risco {
    font-size: 15pt;
    color: #333;
    margin-bottom: 1.5em;
}

.nivel-instrucoes {
    font-size: 15pt;
    color: #333;
    line-height: 1.5;
    white-space: pre-wrap;
}

.salvar-btn {
    background-color: #28a745;
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    padding: 0.75em 2em;
    margin-top: 1.5em;
    border: none;
    border-radius: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1.5em;
}

.salvar-btn:hover {
    background-color: #218838;
}

.org-info-tea {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .pontuacao-display {
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
        margin: 1em 0;
        font-weight: bold;
        padding: 0.1em !important;
    }

    .pontuacao-display-alto {
        background-color: #70441E;
        color: #ffc38f;
        font-size: 3em !important;
    }

    .nivel-tea-card h2 {
        font-size: 1.6em !important;
        font-weight: bold;
        color: #333;
    }

    .nivel-instrucoes {
        font-size: 11pt !important;
        color: #333;
        line-height: 1.5;
        white-space: pre-wrap;
    }

    .nivel-tea-card {
        padding: 2em;
        border-radius: 10px;
        width: 100%;
        max-width: 80%;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        color: #333;
    }

    .pontuacao-risco {
        font-size: 12pt  !important;
        color: #333;
        margin-bottom: 1.5em;
    }

    .nivel-tea-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 13px !important;
        background-color: #e5e5e5 !important;
    }

    .nivel-risco {
        font-size: 1em !important;
        font-weight: bold;
        color: #333;
        margin-top: 0.8em;
    }
}