.pergunta-card {
    background-color: #fff !important;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}


@media (max-width: 768px) {
    .perguntas-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        gap: 20px;
        padding: 0px;
    }
}