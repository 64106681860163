.informacoes-container {
    padding: 2em 4em;
    background-color: #e7e7e7;
}

.informacoes-titulo {
    font-size: 1.8em;
    color: #666;
    font-weight: bold;
    margin-bottom: 10px;
}

.informacoes-subtitulo {
    font-size: 1.4em;
    color: #666;
    margin-bottom: 20px;
}

.informacoes-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(650px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.informacoes-card {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    height: 100px;
    cursor: pointer;
    margin-bottom: 1em;
}

.informacoes-card:hover {
    transform: translateY(-5px);
}

.pdf-icon {
    font-size: 50px;
    color: #5b6bf5;
    margin-right: 10px;
}

.informacoes-card-texto {
    font-size: 20px;
    color: #666;
    font-weight: 500;
}

.informacoes-referencias {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
    text-transform: uppercase;
}

.informacoes-referencias h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.informacoes-referencias p {
    line-height: 1.5;
    font-size: 14pt;
    margin-bottom: 1em;
}

.informacoes-referencias a {
    color: #3366cc;
    text-decoration: none;
}

.informacoes-referencias a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .informacoes-container {
        padding: 2em 1em;
        background-color: #e6e6e6;
    }

    .informacoes-subtitulo {
        font-size: 1em;
        color: #666;
        margin-bottom: 20px;
    }

    .informacoes-titulo {
        font-size: 1.5em;
        color: #666;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .informacoes-cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 8px;
        margin-bottom: 30px;
    }

    .informacoes-card-texto {
        font-size: 14px;
        color: #666;
        font-weight: 500;
    }

    .pdf-icon {
        font-size: 37px;
        color: #5b6bf5;
        margin-right: 10px;
    }

    .informacoes-card {
        display: flex;
        align-items: center;
        padding: 7px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease;
        height: 75px;
    }

    .informacoes-referencias p {
        line-height: 1.5;
        font-size: 10pt;
        margin-bottom: 1em;
    }


}