/* Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #5B6BF5;
    color: #fff;
    font-family: Arial, sans-serif;
}

body {
    background: #fff ;
}


.welcome-title {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 5px;
}

.subtitle {
    font-size: 1.2em;
    margin-bottom: 30px;
}


.login-form {
    background-color: #fff;
    padding: 50px;
    border-radius: 50px;
    width: 468px;
    height: 480px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}




.input-field {
    width: 95%;
    padding: 12px;
    margin: 10px 0;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 1em;
    color: #3c52d1;
    font-size: 14pt;
    font-weight: 600;
}

.password-field {
    position: relative;
    display: flex;
    align-items: center;
}

.toggle-password {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #777;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    color: #777;
    margin-top: 22px;
}

.forgot-password {
    color: #4a63f4;
    font-size: 12pt;
    font-weight: 700;


}

.forgot-password:hover {
    text-decoration: underline;

}


.input-field:focus-visible {
    outline: none;
    border: 2px solid #3c52d1;
}



.login-button {
    background-color: #4a63f4;
    color: #fff;
    font-size: 1em;
    padding: 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    margin-top: 3em;
    transition: background-color 0.3s ease;
    font-weight: 800;
    font-size: 18pt;
}

.login-button:hover {
    background-color: #3c52d1;
}


.register-prompt {
    font-size: 0.9em;
    color: #777;
    margin-top: 60px;
}

.register-link {
    color: #4a63f4;
    text-decoration: none;
}

.register-link:hover {
    text-decoration: underline;
}

.toggle-password-none {
    display: none;
}

.contetn-info-texts h1 {
    font-weight: 800;
    font-size: 38pt;

}

.contetn-info-texts p {
    font-weight: 800;
    font-size: 18pt;
    margin-top: 12px;
}


/* Responsividade para Tablets */
@media (max-width: 768px) {
    .login-form {
        background-color: #fff;
        padding: 1em !important;
        border-radius: 50px 50px 0px 0px !important;

        width: 93% !important;
        height: auto;
        box-shadow: none !important;
        text-align: center;
        height: 100vh !important;

    }

    .contetn-info-texts {
        margin-top: 1em;
    }

    .contetn-info-texts p {
        font-weight: 700;
        font-size: 18pt;
        margin-top: 12px;
        width: 320px !important;
    }

    .input-field {
        width: 90%;
        padding: 12px;
        margin: 10px 0;
        font-size: 1em;
        border: 1px solid #ddd;
        border-radius: 15px;
        padding: 0.9em !important; 
        color: #3c52d1;
        font-size: 14pt;
        font-weight: 600;
    }
}


@media (max-width: 480px) {

    .login-form {
        background-color: #fff;
        padding: 1em !important;
        border-radius: 50px 50px 0px 0px !important;

        width: 91% !important;
        height: auto;
        box-shadow: noone !important;
        text-align: center;
        height: 100vh !important;

    }

    .input-field {
        width: 90%;
        padding: 12px;
        margin: 10px 0;
        font-size: 1em;
        border: 1px solid #ddd;
        border-radius: 15px;
        padding: 0.9em !important;
        color: #3c52d1;
        font-size: 14pt;
        font-weight: 600;
    }
}