/* TutorialMchat.css */

.tutorial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #e7e7e7;
}

.tutorial-card {
    background-color: #ffffff;
    padding: 2em;
    border-radius: 10px;
    max-width: 1060px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tutorial-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
}

.tutorial-icon {
    font-size: 2em;
    color: #4a90e2;
    /* Ajuste a cor conforme necessário */
    margin-right: 10px;
}

.tutorial-card h2 {
    font-size: 2.5em;
    font-weight: bold;
    color: #5b6bf5;
    margin-bottom: 1em;
    margin-left: 0.2em;

}

.tutorial-text {
    font-size: 22px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 1em;
}

@media (max-width: 768px) {}


@media (min-width: 768px) and (max-width: 1400px) {
    .tutorial-container {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: #e7e7e7;
    }

    .tutorial-text {
        font-size: 15px;
        color: #666;
        line-height: 1.6;
        margin-bottom: 1em;
    }

    .tutorial-card h2 {
        font-size: 2.5em;
        font-weight: bold;
        color: #5b6bf5;
        margin-bottom: 0em;
        margin-left: 0.5em;
    }

    .nivel {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        padding: 10px 20px;
        width: 240px;
        height: 85px;
        font-size: 20px;
        font-weight: bold;
        color: #333;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
}

@media (min-width: 720px) and (max-width: 1280px) {
    .tutorial-container {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        background-color: #e7e7e7;
    }

    .tutorial-text {
        font-size: 15px;
        color: #666;
        line-height: 1.6;
        margin-bottom: 1em;
    }

    .tutorial-card h2 {
        font-size: 2.5em;
        font-weight: bold;
        color: #5b6bf5;
        margin-bottom: 0em;
        margin-left: 0.5em;
    }

    .nivel {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        padding: 10px 20px;
        width: 240px;
        height: 85px;
        font-size: 20px;
        font-weight: bold;
        color: #333;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }



}

