/* styles.css */
.senha-container {
    padding: 20px;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2%;
}

.senha-container h2 {
    color: #4a63d0;
    font-size: 3em;
    font-weight: bold;
}

.senha-container p {
    color: #4a63d0;
    font-size: 1.4em;
    margin-top: 15px;
    margin-bottom: 20px;
}

.senha-container label {
    display: block;
    font-size: 1.4em;
    color: #8c8c8c;
    margin-bottom: 10px;
    margin-top: 1em;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-container input {
    width: 89%;
    padding: 20px 40px 20px 20px;
    border: 2px solid #4a63d0;
    border-radius: 1em;
    font-size: 1.5em;
    color: #4a63d0;

}

.input-container span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #4a63d0;
}

.cadastrar-btn {
    width: 100%;
    padding: 15px;
    color: #fff;
    background-color: #4a63d0;
    border: none;
    border-radius: 1em;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.4em;
}

.cadastrar-btn:hover {
    background-color: #3a51b8;
}


@media (max-width: 768px) {
    .senha-container h2 {
        color: #4a63d0;
        font-size: 2em;
        font-weight: 700;
    }

    .senha-container p {
        color: #4a63d0;
        font-size: 1.2em;
        margin-bottom: 20px;
        margin-top: 15px;
    }

    .senha-container label {
        color: #8c8c8c;
        display: block;
        font-size: 1em;
        margin-bottom: 10px;
        margin-top: 1em;
    }

    .input-container input {
        border: 2px solid #4a63d0;
        border-radius: 0.5em;
        color: #4a63d0;
        font-size: 1.5em;
        padding: 10px 40px 10px 20px;
        width: 82%;
    }


}