/* InstitutoAutismo.css */

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #5B6BF5;
    color: #fff;
    font-family: Arial, sans-serif;
}

.content {
    text-align: center;
}

.logo {
    width: 455px;
    height: auto;
    margin-bottom: 20px;
}

.title {
    font-size: 5em;
    font-weight: bold;
    margin-bottom: 10px;
 
}

.description {
    font-size: 1.5em;
    margin-bottom: 30px;

    text-transform: uppercase;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #374eda;
    color: #fff;

    padding: 14px 61px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 24pt;
}

.button:hover {
    background-color: #2d3db8;
}

.arrow-icon {
    margin-left: 10px;
}


.position-button {
    display: flex;
    justify-content: center;
    margin: 1em;
}

/* Responsividade para Tablets */
@media (max-width: 768px) {
    .logo {
        width: 300px;

    }

    .title {
        font-size: 3em;

    }

    .description {
        font-size: 1.2em;
        width: 300px;

    }

    .button {
        padding: 12px 50px;
        font-size: 20pt;
    }
}

/* Responsividade para Dispositivos Móveis */
@media (max-width: 480px) {
    .logo {
        width: 200px;

    }

    .title {
        font-size: 2em;

    }

    .description {
        font-size: 1em;
        width: 300px;

    }

    .button {
        padding: 10px 30px;

        font-size: 16pt;

    }
}