.language-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.flag-icon {
    width: 45px;
    border-radius: 3px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.language-dropdown {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    cursor: pointer;
}