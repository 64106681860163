/* TutorialMchat.css */

.tutorial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.tutorial-card {
    background-color: #ffffff;
    padding: 2em;
    border-radius: 10px;
    max-width: 1060px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tutorial-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
}

.tutorial-icon {
    font-size: 2em;
    color: #4a90e2; /* Ajuste a cor conforme necessário */
    margin-right: 10px;
}

.tutorial-card h2 {
    font-size: 2.5em;
    font-weight: bold;
    color: #5b6bf5;
    margin-bottom: 0em;
    margin-left: 0.2em;

}

.tutorial-text {
    font-size: 22px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 1em;
}



.sidebar-icon-3 {
    width: 6em;
    margin-right: 20px;
    margin-left: 8px;
}


