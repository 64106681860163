

@media (max-width: 768px) {
    .info-card-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background-color: #5865F2 !important; /* Cor azul */
        color: white;
        border-radius: 0 0px 50px 0 !important; /* Borda arredondada na lateral direita */
        font-size: 1rem;
        font-weight: bold;
        width: auto;
        height: 65px; /* Ajuste conforme necessário */
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra leve */
    }

    .info-card-title {
        margin: 0;
        width: 6em;
        font-size: 19pt;
    }

    .info-card-icon {
        width: 24px;
        height: 24px;
    }

    .info-card-2 img {
        filter: brightness(0) invert(1);
        margin-right: 1em;
        height: auto;
        width: 40px;
    }

    /* Estilos para o botão de voltar */
    .back-button {
        margin-left: 10px;
        padding: 8px 16px;
        font-size: 14px;
        background-color: #ff5c5c; /* Cor do botão de voltar */
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .back-button:hover {
        background-color: #ff4040; /* Cor ao passar o mouse */
    }

    .info-card-2 .voltar{
        display: flex;
        align-items: center;
    }
}
