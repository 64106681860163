/* Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #4a63f4;
    color: #fff;
    font-family: Arial, sans-serif;
}


.welcome-title {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 5px;
}

.subtitle {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.contetn-info-texts {
    margin-top: 5em;
}

.login-form {
    background-color: #fff;
    padding: 50px;
    border-radius: 50px;
    margin-bottom: 5em;
    width: 468px;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}




.input-field {
    width: 95%;
    padding: 12px;
    margin: 10px 0;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 1em;
    color: #3c52d1;
    font-size: 14pt;
    font-weight: 600;
}

.password-field {
    position: relative;
    display: flex;
    align-items: center;
}

.toggle-password {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #777;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    color: #777;
    margin-top: 22px;
}

.forgot-password {
    color: #4a63f4;
    font-size: 12pt;
    font-weight: 700;


}

.forgot-password:hover {
    text-decoration: underline;

}


.input-field:focus-visible {
    outline: none;
    border: 2px solid #3c52d1;
}



.login-button {
    background-color: #4a63f4;
    color: #fff;
    font-size: 1em;
    padding: 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    margin-top: 3em;
    transition: background-color 0.3s ease;
    font-weight: 800;
    font-size: 18pt;
}

.login-button:hover {
    background-color: #3c52d1;
}


.register-prompt {
    font-size: 0.9em;
    color: #777;
    margin-top: 60px;
}

.register-link {
    color: #4a63f4;
    text-decoration: none;
}

.register-link:hover {
    text-decoration: underline;
}

.toggle-password-none {
    display: none;
}

.contetn-info-texts h1 {
    font-weight: 800;
    font-size: 30pt;
}

.contetn-info-texts p {
    font-weight: 700;
    font-size: 18pt;
    margin-top: 12px;
}

/* Estilo para o grupo de botões de rádio */
.radio-group {
    margin-top: 20px;
    text-align: left;
}

.radio-label {
    font-size: 1.2em;
    color: #707070;
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
}

.radio-options {
    display: flex;
    gap: 10px;
    align-items: center;
}

.radio-options label {
    font-size: 1.2em;
    color: #707070;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
}

.radio-options input[type="radio"] {
    width: 16px;
    height: 16px;
    accent-color: #4a63f4;
}

/* styles.css */

.input-error {
    border: 2px solid red;
    margin-bottom: 5px;
    /* Adiciona um espaço entre o campo e a mensagem de erro */
}

.error-message {
    color: red;
    font-size: 0.9em;
    margin-top: -5px;
    /* Ajusta o espaçamento para posicionar melhor */
    margin-left: 5px;
    /* Adiciona um pouco de espaço à esquerda */
    text-align: left;
    display: block;
    /* Garante que a mensagem ocupe toda a largura */
}


@media (min-width:768px) and (max-width: 1600px) {
    .login-container {
        height: auto;

    }
}

/* Responsividade para Tablets */
@media (max-width: 768px) {


    .login-form {
        background-color: #fff;
        padding: 50px;
        border-radius: 50px;
        margin-bottom: 0em;
        width: 468px;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        text-align: center;
    }

    .contetn-info-texts {
        margin-top: 0em;
        text-align: center;
    }

    .contetn-info-texts p {
        font-weight: 700;
        font-size: 18pt;
        margin-top: 12px;
        width: auto;
    }


    .login-button {

        margin-top: 2em;

    }



}



/* Responsividade para Dispositivos Móveis */
@media (max-width: 480px) {}