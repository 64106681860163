/* Banner Superior */
.last-instrument-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #b3d4ff;
    border-radius: 1em;
    padding: 3em;
    margin-bottom: 20px;
    position: relative;
    box-shadow: 2px 5px 5px #00000021;
}

.banner-content h2 {
    color: #4a63d0;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.banner-content {
    padding-left: 2em;
}

.banner-content h1 {
    color: #4a63d0;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
}

.view-now-btn {
    display: inline-flex;
    align-items: center;
    background-color: #4a63d0;
    color: #fff;
    padding: 15px 50px;
    border: none;
    border-radius: 1em;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 10px;
}

.view-now-btn svg {
    margin-left: 5px;
}

.banner-icon {
    font-size: 8rem;
    color: #d4e6ff;
}

/* Seção Meus Instrumentos */
.my-instruments-section {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 3em;
    box-shadow: 2px 5px 5px #00000021;
}

.my-instruments-section h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #4a63d0;
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.search-bar input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 1rem;
    width: 200px;
}

.search-icon {
    font-size: 1.2rem;
    color: #4a63d0;
    margin-left: -30px;
    cursor: pointer;
}

.instrument-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
}

.instrument-card {
    background-color: #ffffff;
    border: 2px solid #b3d4ff;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s ease;
    cursor: pointer;
}

.instrument-card:hover {
    transform: scale(1.03);
}

.instrument-card h4 {
    font-size: 1.2rem;
    color: #4a63d0;
    margin: 0 0 5px;
}

.instrument-card p {
    font-size: 0.9rem;
    color: #666;
    margin: 2px 0;
}

.card-icon {
    font-size: 1.2rem;
    color: #4a63d0;
    align-self: flex-end;
}

.org-date-button {

    display: flex;
    align-items: center;
    width: 115%;
    justify-content: space-between;
}

.clock-info {
    background-color: #BFC3EC;
}

/* Estilo para o container do ícone de relógio */
.clock-info-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.clock-info {
    background-color: #BFC3EC;
    padding: 40px;
    border-top-left-radius: 9em;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 9em;
    border-bottom-left-radius: 9em;
    position: absolute;
    top: -132px;
    right: -48px;
}

.banner-icon {
    font-size: 9rem;
    color: #5B6BF5;
}

.content-info-date-btn {
    display: flex;
    width: 500px;
    justify-content: space-between;
    align-items: center;
}

.ContentMobi {
    display: none;
}

@media (max-width: 768px) {
    .main-content {
        margin-left: 0;
        margin-top: 0px;
    }


    .mobi {
        display: none;
    }

    .ContentMobi {
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        border: 2px solid #b3d4ff;
        border-radius: 1em;
        padding: 1em;
        margin-bottom: 20px;
        position: relative;
        box-shadow: 2px 5px 5px #00000021;
    }

    .clock-info-2 {
        background-color: #BFC3EC;
        padding: 21px;
        border-top-left-radius: 9em;
        border-top-right-radius: 3em;
        border-bottom-right-radius: 9em;
        border-bottom-left-radius: 9em;
        position: absolute;
        top: -105px;
        right: -16px;
    }

    .home-container {
        padding: 0em 0em 0em 0em;
    }

    .banner-content h2 {
        color: #4a63d0;
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 10px;
        width: 230px;
    }

    .last-instrument-banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        border: 2px solid #b3d4ff;
        border-radius: 1em;
        padding: 1em;
        margin-bottom: 20px;
        position: relative;
        box-shadow: 2px 5px 5px #00000021;
    }

    .banner-content h1 {
        color: #4a63d0;
        font-size: 24pt;
        font-weight: bold;
        margin: 0;
    }

    .banner-content {
        padding-left: 0em;
    }

    .clock-info {
        background-color: #BFC3EC;
        padding: 21px;
        border-top-left-radius: 9em;
        border-top-right-radius: 3em;
        border-bottom-right-radius: 9em;
        border-bottom-left-radius: 9em;
        position: absolute;
        top: -93px;
        right: -17px;

    }

    .content-info-date-btn {
        display: block;
        width: auto !important;
        justify-content: space-between;
        align-items: center;
    }

    .banner-icon {
        font-size: 3rem;
        color: #5B6BF5;
    }

    .view-now-btn {
        display: inline-flex;
        align-items: center;
        background-color: #4a63d0;
        color: #fff;
        padding: 10px 25px;
        border: none;
        border-radius: 1em;
        font-size: 1.2rem;
        cursor: pointer;
        margin-top: 10px;
    }

    .main-content {

        height: 100vh;

    }

    .home-container {
        padding: 1em 1em 1em 1em !important;
    }



}

@media (min-width: 720px) and (max-width: 1280px) {
    .clock-info {
        background-color: #BFC3EC;
        padding: 20px;
        border-top-left-radius: 9em;
        border-top-right-radius: 1em;
        border-bottom-right-radius: 9em;
        border-bottom-left-radius: 9em;
        position: absolute;
        top: -132px;
        right: -48px;
    }

    .banner-icon {
        font-size: 7rem;
        color: #5B6BF5;
    }

    .my-instruments-section {
        background-color: #ffffff;
        border-radius: 10px;
        padding: 2em;
        box-shadow: 2px 5px 5px #00000021;
    }

    .mchat-container {
        padding: 0em;
        background-color: #e7e7e7;
        border-radius: 1em;
        max-width: 1500px;
        margin: 0 auto;
    }

    .mchat-form input {
        padding: 19px;
        font-size: 1.1rem;
        border: 2px solid #b3d4ff;
        border-radius: 10px;
        outline: none;
        transition: border-color 0.3s;
    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .clock-info {
        background-color: #BFC3EC;
        padding: 20px;
        border-top-left-radius: 9em;
        border-top-right-radius: 1em;
        border-bottom-right-radius: 9em;
        border-bottom-left-radius: 9em;
        position: absolute;
        top: -132px;
        right: -48px;
    }

    .banner-icon {
        font-size: 7rem;
        color: #5B6BF5;
    }

    .my-instruments-section {
        background-color: #ffffff;
        border-radius: 10px;
        padding: 2em;
        box-shadow: 2px 5px 5px #00000021;
    }

    .mchat-container {
        padding: 0em;
        background-color: #e7e7e7;
        border-radius: 1em;
        max-width: 1500px;
        margin: 0 auto;
    }

    .mchat-form input {
        padding: 19px;
        font-size: 1.1rem;
        border: 2px solid #b3d4ff;
        border-radius: 10px;
        outline: none;
        transition: border-color 0.3s;
    }
}