@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  font-family: "Roboto", serif;
}

body {
  background: #E7E7E7 !important;
}

.home-container {
  padding: 2em 8em 5em 5em;
}

.home-container .goback {
  display: flex;
  color: #666;
  font-size: 18pt;
  align-items: center;
  font-weight: bold;

}

.goback {
  cursor: pointer;
  margin-bottom: 1em;

}

.goback p {
  display: flex;
  align-items: center;

}

.goback svg {
  margin-right: 0.5em;
}


.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

.isMobi,
.noMobile {
  display: none !important;
}

.info-card-2 {
  display: none;
}

.isMobiSome {
  display: flex !important;

}


@media (max-width: 768px) {

  .isMobi,
  .info-card-2 {
    display: block;
  }

  .isMobiSome {
    display: none !important;
  }

  .main-content {
    padding: 0;
    height: 100vh;
  }

  .duvidas-container {

    height: 100vh;
  }

  .tutorial-container {
    height: 77vh;
  }

  .voltar .back-div .back-icon {
    font-size: 1.5em;
    color: #ffffff;
    cursor: pointer;
    margin-right: 0.5em;

  }


  .info-card-title {
    margin: 0;
    width: 7em;
    font-size: 13pt;
  }



}