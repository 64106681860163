/* TutorialMchat.css */

.tutorial-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.tutorial-card {
    background-color: #ffffff;
    padding: 2em;
    border-radius: 10px;
    max-width: 1060px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tutorial-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
}

.tutorial-icon {
    font-size: 2em;
    color: #4a90e2;
    /* Ajuste a cor conforme necessário */
    margin-right: 10px;
}

.tutorial-card h2 {
    font-size: 2.5em;
    font-weight: bold;
    color: #5b6bf5;
    margin-bottom: 0em;
    margin-left: 0.2em;
}

.tutorial-text {
    font-size: 22px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 1em;
}

.niveisTEA {
    padding-top: 2em;
}

.niveisTEA h2 {
    color: #666;
    text-align: left;
}


.span-level {
    font-size: 13pt !important;
    margin-top: 0.2em;
}




.niveis-instrucao {
    font-size: 25px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 25px;
}

.niveis-info-tea {
    display: flex;
    gap: 20px;
}

.nivel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px 20px;
    width: 320px;
    height: 85px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.niveis-info-tea span {
    font-size: 20pt;
}

.nivel1 {
    background-color: #a4f6a5;
    /* Verde claro */
    color: #15421f;
}

.nivel2 {
    background-color: #caccff;
    /* Roxo claro */
    color: #333399;
}

.nivel3 {
    background-color: #ffc38f;
    /* Laranja claro */
    color: #b25900;
}

.nivel:hover {
    transform: scale(1.05);
    transition: transform 0.2s;
}

@media (max-width: 768px) {
    .tutorial-header {
        display: none;
    }

    .tutorial-text {
        font-size: 12px;
        color: #666;
        line-height: 1.6;
        margin-bottom: 1em;
    }

    .tutorial-container {

        background-color: #e7e7e7;
    }

    .niveisTEA {
        padding-top: 0em;
    }

    .nivel {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        padding: 0px 18px;
        width: auto;
        height: 85px;
        font-size: 20px;
        font-weight: bold;
        color: #333;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .niveis-info-tea span {
        font-size: 10pt;
        text-align: center;
    }

    .span-level {
        font-size: 7pt !important;
        margin-top: .2em;
    }

    .niveis-instrucao {
        font-size: 18px;
        font-weight: 600;
        color: #666666;
        margin-bottom: 14px;
        margin-top: 18px;
    }

    .modal-content button {
        background-color: #4caf5000 !important;
        color: #000000 !important;
        padding: 15px 35px;
        border: none;
        border-radius: 1em;
        cursor: pointer;
        font-size: 12pt;
        margin-bottom: 0em;
    }

    .modal-close {
        position: absolute;
        top: 12px !important;
        right: -6px !important;
    }

    .modal-content p {
        font-size: 14px !important;
        margin-bottom: 10px;
        color: #666;
    }

    .modal-content h2 {
        margin: 0;
        padding-bottom: 10px;
        font-size: 1.1rem !important;
        color: #666;
    }

    .modal-content h3 {
        font-size: 1em !important;
        margin-bottom: 10px;
        color: #666;
    }

    .modal-content {


        width: 80% !important;

    }

    .org-btn {
        display: flex;
        justify-content: center;
        margin-bottom: 1em;
        margin-top: 2em;
    }

    .tutorial-container {

        padding: 10px;
    }

    .niveis-info-tea {
        display: flex;
        gap: 20px;
        margin-bottom: 1em;
    }

}